import img1 from '../images/image1.png'
import img2 from '../images/image2.png'
import img3 from '../images/image3.png'
import img4 from '../images/image4.png'
import img5 from '../images/image5.png'
import img6 from '../images/image6.png'
import img7 from '../images/image7.png'
import img8 from '../images/image8.png'
import img9 from '../images/image9.png'

export const SliderData =[
    {
      img:img1
    },
    {
        img:img2
      },
      {
        img:img3
      },
      {
        img:img4
      },
      {
        img:img5
      },
      {
        img:img6
      },
      {
        img:img7
      },
      {
        img:img8
      },
      {
        img:img9
      },
]