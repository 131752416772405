export const Users = [
    {
        "name":"Chethan",
        "qualification":"BE",
        "location" : "Bangalore",
        "jobrole": "Front-end-developer",
        "contactnumber":8654673445,
    },
    {
        "name":"Vijay",
        "qualification":"BSC",
        "location" : "Chennai",
        "jobrole": "back-end-developer",
        "contactnumber":4666709664,
    },
    {
        "name":"Akash",
        "qualification":"BE",
        "location" : "Chennai",
        "jobrole": "back-end-developer",
        "contactnumber":9867986798,
    },
    {
        "name":"Vinay",
        "qualification":"BE",
        "location" : "Bangalore",
        "jobrole": "Front-end-developer",
        "contactnumber":9867687694,
    },
    {
        "name":"Abhi",
        "qualification":"BSC",
        "location" : "Chennai",
        "jobrole": "Front-end-developer",
        "contactnumber":6798649786,
    },
    {
        "name":"Bharath",
        "qualification":"BE",
        "location" : "Mumbai",
        "jobrole": "Front-end-developer",
        "contactnumber":5469965494,
    },
    {
        "name":"Dhanush",
        "qualification":"BE",
        "location" : "Bangalore",
        "jobrole": "back-end-developer",
        "contactnumber":9879687996,
    },
    {
        "name":"Naveen",
        "qualification":"BE",
        "location" : "Mumbai",
        "jobrole": "front-end-developer",
        "contactnumber":7986978469,
    },
    {
        "name":"Chandhan",
        "qualification":"BE",
        "location" : "Chennai",
        "jobrole": "back-end-developer",
        "contactnumber":9789869756,
    },
    {
        "name":"Shankar",
        "qualification":"BE",
        "location" : "Chennai",
        "jobrole": "back-end-developer",
        "contactnumber":9868547683,
    },
    {
        "name":"Bhuvan",
        "qualification":"BE",
        "location" : "Bangalore",
        "jobrole": "front-end-developer",
        "contactnumber":4766476664,
    },
    {
        "name":"Harish",
        "qualification":"BE",
        "location" : "Chennai",
        "jobrole": "back-end-developer",
        "contactnumber":9689487946,
    },
   
]